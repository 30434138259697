import type { UserSettingsUpdate } from 'back/src/services/User'
import { useUserStore } from '#imports'
import { onBeforeMount, watch } from 'vue'

type TThemeColor = UserSettingsUpdate['themeColor']
export function useAppTheme() {
    const userStore = useUserStore()

    watch(() => userStore.settings.themeColor, setTheme)

    onBeforeMount(() => setTheme(userStore.settings.themeColor))

    function setTheme(color: TThemeColor) {
        const headTag = document.getElementsByTagName('head')[0]
        let styleTag = document.querySelector('#theme-color')

        if (!styleTag) {
            styleTag = document.createElement('style')
            styleTag.id = 'theme-color'
            headTag.appendChild(styleTag)
        }

        styleTag.innerHTML = `
:root {
    --app-color-a: var(--${color}-a);
    --app-color-b: var(--${color}-b);
    --app-color-c: var(--${color}-c);
    --app-color-d: var(--${color}-d);
    --app-color-e: var(--${color}-e);
    --app-color-f: var(--${color}-f);
    --app-color-g: var(--${color}-g);
    --app-color-h: var(--${color}-h);
}
`
    }
}
